<template>
  <div>
    <title-bar :items="title" >
      <template #buttons>
        <p class="has-text-weight-light is-italic">Actualizado el {{dashboard.updated_at}}</p>
      </template>
    </title-bar>
    <div class="is-clearfix mt-2">
      <ListPanel />
    </div>
  </div>
</template>

<script>
import ListPanel from "@/modules/form/1623469803663/components/index/paneles/ListPanel";
export default {
  components: { ListPanel },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    title(){
      return this.$store.getters[`form_1617124496702/title_bar`]()
    }
  }
};
</script>
